@use "~arccorp-vars/src/scss/main.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700;900&display=swap");

//bootstrap override
$font-family-sans-serif: "Source Sans Pro", arial, helvetica, sans-serif;

$theme-colors: (
  "primary": main.$arc-teal,
  "light": main.$white,
);
