@use "~arccorp-vars/src/scss/main.scss";

@import "./scss/variables";
@import "./../node_modules/bootstrap/scss/bootstrap.scss";

.btn-primary,
.btn-primary:hover {
  color: main.$white;
}

.page-link {
  color: main.$arc-teal;
}

.page-item.active .page-link {
  color: main.$white;
  background-color: main.$arc-teal;
  border-color: main.$arc-teal;
}

.jumbo-container {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.arc-bulletin-list {
  border-radius: main.$border-radius-lg;
  //background: #f6f6f6;
  padding: 30px;
  margin-bottom: 3rem;
  &.tarmac {
    background: main.$tarmac;
  }
}

.bulletin-jumbo {
  padding: 30px;
  //margin-bottom: 3rem;
  border-radius: main.$border-radius-lg;
  background: main.$arc-teal;
  color: main.$white;
  //margin-bottom: main.$spacing-4;
}

.bulletin-title-container {
  padding-bottom: 10px;
}

.bulletin-title {
  font-size: 48px;
  font-family: main.$arc-font-semibold;
}

.bulletin-subtitle {
  font-size: 24px;
  line-height: 31px;
}

.table {
  th {
    padding: 15px;
  }
  tr td {
    padding: 10px;
  }
}

.modal-80w {
  width: 80%;
  max-width: none !important;
}

.form-label {
  font-family: main.$arc-font-bold;
}

.bulletin-box-link {
  position: relative;
  font-size: 24px;
  font-family: main.$arc-font-semibold;
  background: main.$white;
  border: 1px solid main.$overcast;
  padding: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: main.$tarmac;

  &:hover {
    border-color: main.$tarmac;
  }

  i {
    justify-self: flex-end;
    margin-left: auto;
  }
}

.coming-soon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  color: main.$white;
  background: main.$overcast;
  padding: 5px;
}

h2.bulletin-sub {
  color: main.$tarmac;
  font-family: main.$arc-font-semibold;
  font-size: 36px;
}
